import { TryckhusetWindow } from "./window";

declare let window: TryckhusetWindow;

const numberOpts: any = {};
numberOpts.style = "currency";
numberOpts.currency = "SEK";

const lang = 'sv';

export const currencyFormatter = new window.Intl.NumberFormat(lang, numberOpts);
export const dateFormatter = new Intl.DateTimeFormat('sv-SE', {
  formatMatcher: 'best fit',
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit'
});

export function currencyFormat(n) {

    if (window.Intl) {
        return currencyFormatter.format(n);
    } else {
        return n;
    }
}

export function formatDate(d: Date): string {
  return dateFormatter.format(d);
}
