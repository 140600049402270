﻿window['LocalTime'].config.i18n['sv'] = {
  date: {
    dayNames: [
      "",
      "måndag",
      "tisdag",
      "onsdag",
      "torsdag",
      "fredag",
      "lördag",
      "söndag"
    ],
    abbrDayNames: [
      "",
      "Må",
      "Ti",
      "On",
      "To",
      "Fr",
      "Lö",
      "Sö"
    ],
    monthNames: [
      "Janari",
      "Februari",
      "Mars",
      "April",
      "Maj",
      "Juni",
      "Juli",
      "Augusti",
      "September",
      "Oktober",
      "November",
      "December"
    ],
    abbrMonthNames: [
      "jan",
      "feb",
      "mar",
      "apr",
      "maj",
      "jun",
      "jul",
      "aug",
      "sep",
      "okt",
      "nov",
      "dec"
    ],
    yesterday: "i går",
    beforeyesterday: "i förrgår",
    previous: "föregående {day}",
    daysago: "{days} dagar sedan",
    today: "i dag",
    tomorrow: "i morgen",
    aftertomorrow: "i övermorgon",
    next: "{day}",
    on: "den {date}",
    formats: {
      default: "%e. %b, %Y",
      thisYear: "%e. %b"
    }
  },
  time: {
    am: "am",
    pm: "pm",
    singular: "ett {time}",
    singularAn: "en {time}",
    elapsed: "{time} sedan",
    second: "sekund",
    seconds: "sekund",
    minute: "minut",
    minutes: "minuter",
    hour: "timme",
    hours: "timmar",

    formats: {
      default: "%H:%M"
    }
  },
  datetime: {
    at: "{date} kl. {time}",
    formats: {
      default: "%B %e, %Y at %l:%M%P %Z"
    }
  }
};
