import * as jQuery from 'jquery';

import { checkWorkstationFn } from './filter-functions';

declare var Tryckhuset: any;

jQuery.fn.dataTable.ext.afnFiltering.push(function (oSettings, aData: string[], iDataIndex: number) {
  var filter = oSettings.nTable.dataset.filter;
  if (filter && filter.indexOf('operators-summary') > -1) {
    return checkWorkstationFn(oSettings, iDataIndex);
  }

  return true;
});