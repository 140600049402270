import * as jQuery from 'jquery';

jQuery.extend(jQuery.fn.dataTable.ext.oSort, {
  "DataDate-asc": (a, b) => {
    var ad = Date.parse(jQuery(a).attr("datetime"));
    var bd = Date.parse(jQuery(b).attr("datetime"));

    if (isNaN(ad))
      return -1;

    if (isNaN(bd))
      return 1;

    if (ad < bd)
      return -1;

    if (ad > bd)
      return 1;

    return 0;
  },

  "DataDate-desc": (a, b) => {
    var ad = Date.parse(jQuery(a).attr("datetime"));
    var bd = Date.parse(jQuery(b).attr("datetime"));

    if (isNaN(ad))
      return 1;

    if (isNaN(bd))
      return -1;

    if (ad < bd)
      return 1;

    if (ad > bd)
      return -1;

    return 0;
  }
});

// Detects <time datetime="some date JS can parse">some string</time>
// and tells DataTable to sort it using the parsable date
jQuery.fn.dataTable.ext.aTypes.unshift((sData) => {
  try {
    var result = jQuery(sData).find("time");
    if (result && result.attr("datetime") != "" && !isNaN(Date.parse(result.attr("datetime"))))
      return 'DataDate';
  }
  catch (err) {
    return null;
  }

  return null;
});
