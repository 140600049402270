/// <reference types="pdfjs-dist" />
import pdfjsLib from './vendor/pdfjs-webpack';

import { PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist';

export class PdfViewer {
  static configure(workerPath: string): void {
    pdfjsLib.GlobalWorkerOptions.workerSrc = workerPath;
  }

  static init(url: string, containerId: string = 'pdfContainer', canvasId: string = 'canvas', prefix: string = 'proof'): PdfViewer {
    return new PdfViewer(url, containerId, canvasId, prefix);
  }

  static addPreviewListeners(): void {
    $("body")
      .on('click', '[data-proof]', function (event) {
        const url = $(event.target).data('proof');
        $("#proof-nav-download").attr('href', url);
        PdfViewer.clear("proof-canvas");
        PdfViewer.init(url, "proof-container", "proof-canvas");
      })
      .on('click', '[data-pdf-preview]', function (event) {
        const url = $(event.target).data('pdf-preview');
        $("#preview-nav-download").attr('href', url);
        PdfViewer.clear("preview-canvas");
        PdfViewer.init(url, "preview-container", "preview-canvas", 'preview');
      });
  }

  static clear(canvasId: string): void {
    var canvas = document.getElementById(canvasId) as HTMLCanvasElement;
    var context = canvas.getContext('2d');
    canvas.height = window.innerHeight;
    canvas.width = window.innerWidth - 30;
    context.clearRect(0, 0, canvas.width, canvas.height);
  }

  containerId: string;
  canvasId: string;
  pdf: any;
  pageNum = 1;
  prevNav: HTMLAnchorElement;
  nextNav: HTMLAnchorElement;

  constructor(url: string, containerId: string = 'pdfContainer', canvasId: string = 'canvas', prefix: string = 'proof') {
    this.containerId = containerId;
    this.canvasId = canvasId;
    pdfjsLib.getDocument(url).then((pdf: PDFDocumentProxy) => this.pdfHandler(pdf, this));

    this.prevNav = document.getElementById(`${prefix}-nav-prev`) as HTMLAnchorElement;
    this.nextNav = document.getElementById(`${prefix}-nav-next`) as HTMLAnchorElement;

    if (this.prevNav) this.prevNav.addEventListener('click', () => this.onPrevPage());
    if (this.nextNav) this.nextNav.addEventListener('click', () => this.onNextPage());
  }

  pdfHandler(pdf, viewer: PdfViewer): void {
    viewer.pdf = pdf;

    // Handle nav buttons
    if (this.prevNav) this.prevNav.classList.add('disabled');
    if (this.nextNav && pdf._pdfInfo.numPages == 1)
      this.nextNav.classList.add('disabled');

    // Fetch the page.
    viewer.queueRenderPage(1);
  }

  queueRenderPage(pageNum: number): void {
    var loader = document.querySelectorAll(`${this.containerId} .loader`);
    loader.forEach(i => i.classList.remove('d-none'));

    this.pdf.getPage(pageNum).then((page: PDFPageProxy) => {
      // Prepare canvas using PDF page dimensions.
      var canvas = document.getElementById(this.canvasId) as HTMLCanvasElement;
      var context = canvas.getContext('2d');
      canvas.height = page.view[3];
      canvas.width = page.view[2];

      var unscaledViewport = page.getViewport(1);
      var scale = Math.min((canvas.height / unscaledViewport.height), (canvas.width / unscaledViewport.width));
      var viewport = page.getViewport(scale);

      // Render PDF page into canvas context.
      var renderContext = {
        canvasContext: context,
        viewport: viewport
      };

      var renderTask = page.render(renderContext);
      (renderTask as any).promise.then(() => {
        var loader = document.querySelectorAll(`#${this.containerId} .loader`);
        loader.forEach(i => i.classList.add('d-none'));
      }, (reason) => console.error(`Failed to render page ${pageNum}:`, reason));
    }, (reason) => console.error("Failed to render PDF:", reason));
  }

  /**
   * Displays previous page.
   */
  onPrevPage() {
    if (this.pageNum <= 1) {
      return;
    }

    this.pageNum--;
    if (this.pageNum == 1)
      this.prevNav.classList.add('disabled');
    this.nextNav.classList.remove('disabled');
    this.queueRenderPage(this.pageNum);
  }

  /**
   * Displays next page.
   */
  onNextPage() {
    if (this.pageNum >= this.pdf._pdfInfo.numPages) {
      return;
    }

    this.pageNum++;
    this.prevNav.classList.remove('disabled');
    if (this.pageNum == this.pdf._pdfInfo.numPages)
      this.nextNav.classList.add('disabled');
    this.queueRenderPage(this.pageNum);
  }
}
