import * as jQuery from 'jquery';

jQuery.fn.dataTable.ext.afnFiltering.push(function (oSettings: DataTables.SettingsLegacy, aData: string[], iDataIndex: number) {
  var filter = (oSettings.nTable as HTMLTableElement).dataset.filter;
  var check = $(oSettings.nTable).parents(".error-table-container").find(".resolved-filter")[0] as HTMLInputElement;

  if (filter && filter.indexOf('error') > -1) {
    if (check.checked)
      return true;

    var nodes = jQuery((oSettings.aoData[iDataIndex] as any).anCells[0]);
    if (nodes.length == 0)
      return false;

    var resolvedNode = nodes.find("[data-resolved]");
    // Something went wrong
    if (resolvedNode.length == 0)
      return false;

    var resolved = resolvedNode.data('resolved');
    if (resolved == "")
      return false;

    return resolved === "False";
  }

  return true;
});