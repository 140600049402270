export class Uploader {
  public static init(id: string): Uploader {
    return new Uploader(id);
  }

  constructor(id: string) {
    $(id).fileupload({
      dataType: 'json',
      singleFileUploads: true,
      done: function (e, data) {
        $.each(data.result, function (index, file) {
          $(`<li class="list-group-item list-group-item-success">
              <input type="hidden" name="FileIds[]" value="${file.id}"/>
              <a href="/Files/Download/${file.id}">${file.name}</a>
              <span class="pull-right"><a href="#" data-remove="attachment"><i class="fa fa-trash-o"></i></a></span>
            </li>`).appendTo('#files');
        });
      },
      progressall: function (e, data) {
        var progress = data.loaded / data.total * 100;
        $('#progress .progress-bar').css(
          'width',
          progress + '%'
        );
      }
    })
      .prop('disabled', !$.support.fileInput)
      .parent().addClass($.support.fileInput ? undefined : 'disabled');

    $("#files").on('click', '[data-remove=attachment]', function () {
      $(this).parents("li").fadeOut(300, function () { $(this).remove() });
    })
  }
}