export function createEvent(eventName) {
    if (typeof (Event) === 'function') {
        var event = new Event(eventName);
        return event;
    } else {
        var event = document.createEvent('Event');
        event.initEvent(eventName, true, true);
        return event;
    }
}
