import * as jQuery from 'jquery';
import { orderIsMine, checkWorkstationFn } from './filter-functions';

declare var Tryckhuset: any;
var lateCheck = document.getElementById("late-deliveries") as HTMLInputElement;
var ownCheck = document.getElementById("own-orders") as HTMLInputElement;

var checkLateFn = (oSettings, iDataIndex: number, aData: string[], val: boolean): boolean => {
  // If checkbox is disabled, show all
  if (!val)
    return true;

    // Parse our cell containing data
    // If it shits the bed, say it's not mine
    var nodes = jQuery(oSettings.aoData[iDataIndex].anCells[0]);
    if (nodes.length == 0)
      return false;

    var timeNode = nodes.find("time");
    if (timeNode.length == 0)
      return false;

    var time = timeNode.attr('datetime');
    if (time == "")
      return false;

    var yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return new Date(Date.parse(time)) < yesterday && !oSettings.aoData[iDataIndex].nTr.classList.contains('success');
}

var checkOwnOrderFn = (oSettings, iDataIndex: number, checked: boolean): boolean => {
  // If checkbox is disabled, show all
  if (!checked)
    return true;

  return orderIsMine(oSettings, iDataIndex);
}

jQuery.fn.dataTable.ext.afnFiltering.push(function (oSettings: DataTables.SettingsLegacy, aData: string[], iDataIndex: number) {
  if (lateCheck == null || ownCheck == null || Tryckhuset.Filtering.VisibleWorkstations == null) {
    return true;
  }

  var filter = (oSettings.nTable as HTMLTableElement).dataset.filter;
  if (filter && filter.indexOf('incoming-deliveries') > -1) {
    var workstationFilter = checkWorkstationFn(oSettings, iDataIndex);
    var lateFilter = checkLateFn(oSettings, iDataIndex, aData, lateCheck.checked);
    var ownFilter = checkOwnOrderFn(oSettings, iDataIndex, ownCheck.checked);

    return workstationFilter && lateFilter && ownFilter;
  }

  return true;
});