import intersection from 'lodash-es/intersection';

declare var Tryckhuset: any;

export function orderIsDelivered(oSettings, iDataIndex: number): boolean {
  // Parse our cell containing data
  // If it shits the bed, say it's not delivered
  var nodes = jQuery(oSettings.aoData[iDataIndex].anCells[7]);
  if (nodes.length == 0)
    return false;

  var dataNode = nodes.find("[data-delivered]");
  // Something went wrong, say it's not delivered
  if (dataNode.length == 0)
    return false;

  // No delivery data found, say it's not delivered
  var data = dataNode.data('delivered');
  if (data == "")
    return false;

  return data === 'True';
}

export function orderIsMine(oSettings, iDataIndex: number): boolean {
  // Parse our cell containing data
  // If it shits the bed, say it's not mine
  var nodes = jQuery(oSettings.aoData[iDataIndex].anCells[0]);
  if (nodes.length == 0)
    return false;

  var idNode = nodes.find("[data-owner-id]");
  // Something went wrong, say it's not mine
  if (idNode.length == 0)
    return false;

  var id = idNode.data('owner-id');
  // No owner configured, it's not mine
  if (id == "")
    return false;

  return Tryckhuset.CurrentUserId === id;
}

export function orderIsPrepared(oSettings, iDataIndex: number): boolean {
  // Parse our cell containing data
  // If it shits the bed, say it's not prepared
  var nodes = jQuery(oSettings.aoData[iDataIndex].anCells[8]);
  if (nodes.length == 0)
    return false;

  var dataNode = nodes.find("[data-prepared]");
  // Something went wrong, say it's not prepared
  if (dataNode.length == 0)
    return false;

  // No preparation data found, say it's not prepared
  var val = dataNode.data('prepared');
  if (val == "")
    return false;

  return val === "True";
}

export function orderHasProof(oSettings, iDataIndex: number): boolean {
  // Parse our cell containing data
  // If it shits the bed, say it's not proofed
  var nodes = jQuery(oSettings.aoData[iDataIndex].anCells[6]);
  if (nodes.length == 0)
    return false;

  var dataNode = nodes.find("[data-proofed]");
  // Something went wrong, say it's not proofed
  if (dataNode.length == 0)
    return false;

  var val = dataNode.data('proofed');
  // No proof data found, say it's not proofed
  if (val == "")
    return false;

  return val === "True";
}

export function orderHasProofResponse(oSettings, iDataIndex: number, want: string = 'True'): boolean {
  // Parse our cell containing data
  // If it shits the bed, say it's not proofed
  var nodes = jQuery(oSettings.aoData[iDataIndex].anCells[6]);
  if (nodes.length == 0)
    return false;

  var dataNode = nodes.find("[data-proof-response]");
  // Something went wrong, say it's not proofed
  if (dataNode.length == 0)
    return false;

  var val = dataNode.data('proof-response');
  // No proof data found, say it's not proofed
  if (val == '')
    return false;

  return val === want;
}

export function checkWorkstationFn(oSettings, iDataIndex: number, showIfEmpty = true): boolean {
  // Show all if we haven't selected any workstations
  if (Tryckhuset.Filtering.VisibleWorkstations.length == 0 && showIfEmpty)
    return true;

  // Parse our cell containing data
  // If it shits the bed, show it
  var nodes = jQuery(oSettings.aoData[iDataIndex].anCells[0]);
  if (nodes.length == 0)
    return true

  // Something went wrong, just show it
  var idNode = nodes.find("[data-workstation-ids]");
  if (idNode.length == 0)
    return true;

  // No workstations configured, hide it
  var ids = idNode.data('workstation-ids');
  if (ids == "")
    return false;

  return intersection(Tryckhuset.Filtering.VisibleWorkstations, ids.split(',')).length > 0;
}

export function checkDayFn(oSettings, iDataIndex: number, aData: string[], days: number[]): boolean {
    // Parse our cell containing data
    // If it shits the bed, say it's not today
    var nodes = jQuery(oSettings.aoData[iDataIndex].anCells[0]);
    if (nodes.length == 0)
        return false;

    var timeNode = nodes.find("time");
    if (timeNode.length == 0)
        return false;

    var time = timeNode.attr('datetime');
    if (time == "")
        return false;

    var date = new Date(Date.parse(time));
    return days.indexOf(date.getDay()) > -1;
}
