
export class User {
    static init() {
        return new User();
    }

    constructor() {
        this.addEventListeners();
    }

    private addEventListeners(): void {
        $("select#Roles").change(this.roleChangedHandler);
        this.roleChangedHandler();
    }

    private roleChangedHandler(): void {
        const roles: string[] = $("select#Roles").val() as string[];

        if (roles == null || (roles.indexOf("Preparator") == -1 && roles.indexOf("Administrator") == -1)) {
            $("#signature-row").slideUp();
        } else {
            $("#signature-row").slideDown();
        }
    }
}
